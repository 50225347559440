<template>
  <div class="service-grid-item service-grid-item--style2">
    <div class="service-grid-item__image">
      <div class="service-grid-item__image-wrapper">
        <router-link :to="'/project-details?info=' + jsonData">
          <img
            :src="project.thumb"
            class="img-fluid"
            style="height: 280px; width: 360px"
          />
        </router-link>
      </div>
    </div>
    <div class="service-grid-item__content">
      <h3 class="title">
        <router-link to="/Service">
          {{ project.name }}
        </router-link>
      </h3>
      <p class="subtitle" style="height: 30px">{{ project.address }}</p>
      <!-- <router-link to="/project-details" class="see-more-link">查看更多</router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["project", "jsonData"],
};
</script>

<style lang="scss">
</style>