<template>
  <div class="main-container">
    <Header />

    <Breadcrumb :items="items" title="恋胜入驻商家" />

    <!-- Projects section start -->
    <div class="page-wrapper section-space--inner--120">
      <div class="project-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="project-item-wrapper">
                <div class="row">
                  <div
                    class="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
                    v-for="project in data.projectGrid"
                    :key="project.id"
                  >
                    <ProjectGrid :project="project" :jsonData="Obj2Json(project)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row section-space--top--60">
            <div class="col">
              <ul class="page-pagination">
                <li>
                  <a href="#"><i class="fa fa-angle-left"></i> Prev</a>
                </li>
                <li class="active"><a href="#">01</a></li>
                <li><a href="#">02</a></li>
                <li><a href="#">03</a></li>
                <li>
                  <a href="#"><i class="fa fa-angle-right"></i> Next</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Projects section end -->

    <!-- <BrandCarousel addClass="grey-bg" /> -->

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import data from "../data/project.json";
import Header from "@/components/Header";
import Breadcrumb from "../components/Breadcrumb";
import ProjectGrid from "../components/ProjectGrid";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  components: {
    Header,
    Breadcrumb,
    ProjectGrid,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
  },
  data() {
    return {
      data,
      items: [
        {
          text: "首页",
          to: "/",
        },
        {
          text: "商家",
          active: true,
        },
      ],
    };
  },
  metaInfo: {
    title: "恋胜 - 商家展示",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {
    let that = this;
    that.$axios.get("/api/v1/advertising/portal").then((res) => {
      console.log(res);
      that.data.projectGrid = res.data.data;
    });
  },
  methods: {
    Obj2Json(data) {
      return JSON.stringify(data);
    },
  },
};
</script>

